import { Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";

const Platform = ({ t, image, logo, title, subTitle, isLocalImg, isLocalImgLogo, onDownload }) => {
  return (
    <Col xs="12" md="6" lg="4" onClick={onDownload}>
      <CardWrapper className="pt-0 px-0 shadow" style={{ 
        cursor: "pointer",
        overflow: "hidden",
      }} >
        <div 
          style={{
            minHeight: "200px",
          }}
        >
          <img src={isLocalImg ? `/img/platforms/${image}.png` : image} />
        </div>
        <div className="d-flex align-items-center text-center mt-3 px-3">
          {/* <img width="60px" height="60px" src={isLocalImgLogo ? `/img/platforms/${logo}.png` : logo} /> */}
          <div className="d-flex flex-column align-items-end text-center  ms-3">
            <h6 className="lh-sm">{t(title)}</h6>
            <small className="text-muted">{t(subTitle)}.</small><br />
          </div>
        </div>
      </CardWrapper>
    </Col>
  );
};

export default withTranslation()(Platform);